import { createAction } from "@reduxjs/toolkit";
import { AddressModel } from "src/components/core/AddressForm/utils";

export const userAcceptedSuggestedAddress = createAction<{
  suggestedAddress: Partial<AddressModel>;
  isSameAsBillingAddress: boolean;
  shouldUpdateFormAddress: boolean;
}>("checkoutPhysicalAddress/userAcceptedSuggestedAddress");

export const formAddressUpdatedWithSuggestedAddress = createAction(
  "checkoutPhysicalAddress/formAddressUpdatedWithSuggestedAddress"
);

export const suggestAddress = createAction<Partial<AddressModel>>(
  "checkoutPhysicalAddress/suggestAddress"
);

export const returnedInvalidAddress = createAction(
  "checkoutPhysicalAddress/returnedInvalidAddress"
);

export const failNoPOBoxValidation = createAction(
  "checkoutPhysicalAddress/failNoPOBoxValidation"
);

export const failZipCodeMedValidation = createAction(
  "checkoutPhysicalAddress/failZipCodeMedValidation"
);

import { singularSdk, SingularConfig } from "singular-sdk";
import { appConfig } from "src/config";
import { getInAppAutoCookie } from "src/utils/authCookieParser";

export function loadSingular() {
  if (getInAppAutoCookie()) {
    // Singular SDK should not initialize if in-app auto-login cookie is set.
    return Promise.resolve();
  }

  const singularConfig = new SingularConfig(
    appConfig.SINGULAR_SDK_KEY,
    appConfig.SINGULAR_SDK_SECRET,
    appConfig.SINGULAR_SDK_PRODUCT_ID
  );

  singularSdk.init(singularConfig);

  return Promise.resolve();
}

import { trackEvent } from "@utils/api/tracker";
import { fireFacebookCapi } from "@utils/api/FacebookCapiApi";
import { generateEventId } from "@utils/pixels/utils";
import { shouldAdjustELTV, adjustELTV, extractAdName } from "../eltvAdjustment";
import { registerHandler } from "../handler";
import {
  allowCategories,
  consentFramework,
  LibCategories,
} from "src/utils/consent";
import { getCountryCode } from "src/utils/meristemContext";
import { optOutAllowsCookies } from "src/utils/consent/opt-out";
import { shouldFireMedPixel } from "src/utils/userSegment/features";

declare const fbq: any;

interface FacebookAdvancedMatchingParams {
  em?: string;
  fn?: string;
  ln?: string;
  email?: string;
  external_id?: string;
  ph?: string;
}

export interface FacebookPixelParameters {
  order_id?: string;
  external_id?: string;
  content_category?: string;
  content_ids?: string;
  content_type?: string;
  value?: number;
  currency?: string;
  product?: string;
}

const FB_ELTV13_PIXEL = "1557275494440599";
const FB_FORBES_PIXEL = "830806628259122";
const NOOM_WEIGHT_META_PIXEL = "783435153677650";
const NOOM_MED_META_PIXEL = "540736718278208";

/**
 * Determines which META pixel we need to fire.
 */
function getMetaPixelId() {
  if (shouldFireMedPixel()) {
    return NOOM_MED_META_PIXEL;
  }
  return NOOM_WEIGHT_META_PIXEL;
}

/**
 * Enables the advance matching feature for Facebook Pixel.
 */
function initAdvancedMatching(PIXEL_ID: string, userId: string) {
  const advancedMatchingParams: FacebookAdvancedMatchingParams = {
    ...(userId && { external_id: userId }),
  };

  // NOTE(Patrick): We already do this in the `init` handler but the logic
  // in ConversionTracker fires it twice so leaving it in.
  // Check to add LDU flag for CCPA opt out.
  if (shouldApplyLDUFlag()) {
    applyLDUFlag();
  }

  fbq("init", PIXEL_ID, advancedMatchingParams);
}

/**
 * Workaround for being able to add information as advanced matching latter in the funnel (if the purchase is successful)
 * This would have not been possible otherwise as the advanced matching parameters can only be added at the first call of 'init'
 */
function reinitForMatchingParameters(PIXEL_ID) {
  if (allowCategories([LibCategories.targetingCookies])) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://connect.facebook.net/signals/config/${PIXEL_ID}?v=2.9.48&r=stable`;
    script.id = "facebookEltv13PixelScript";

    const head = document.getElementsByTagName("head")[0];
    head.appendChild(script);
  }
}

export function shouldApplyLDUFlag() {
  return consentFramework() === "optOut" && !optOutAllowsCookies();
}

export function applyLDUFlag() {
  fbq("dataProcessingOptions", ["LDU"], 1, 1000);
}

export default function registerHandlers() {
  registerHandler("init", () => {
    const META_PIXEL_ID = getMetaPixelId();
    // Check to add LDU flag for CCPA opt out.
    if (shouldApplyLDUFlag()) {
      applyLDUFlag();
    }

    fbq("init", FB_ELTV13_PIXEL);
    fbq("init", FB_FORBES_PIXEL);
    fbq("init", META_PIXEL_ID);

    // For Meta We are using 2 pixels. The old FB_ELTV13_PIXEL
    // and either NOOM_MED_META_PIXEL/NOOM_WEIGHT_META_PIXEL depending on the route.
    // The Forbes pixel is just using the Meta platform
    const eventIDEltv13Payload = { eventID: generateEventId() };
    fbq("trackSingle", FB_ELTV13_PIXEL, "PageView", {}, eventIDEltv13Payload);
    fireFacebookCapi(FB_ELTV13_PIXEL, "PageView", eventIDEltv13Payload);

    const eventIDNewPixelPayload = { eventID: generateEventId() };
    fbq("trackSingle", META_PIXEL_ID, "PageView", {}, eventIDNewPixelPayload);
    fireFacebookCapi(META_PIXEL_ID, "PageView", eventIDNewPixelPayload);

    fbq("trackSingle", FB_FORBES_PIXEL, "PageView");
  });

  registerHandler("viewContent", (params) => {
    const META_PIXEL_ID = getMetaPixelId();

    const oldPixeleventID = generateEventId(params.userId);
    fbq("trackSingle", FB_ELTV13_PIXEL, "ViewContent", params, {
      eventID: oldPixeleventID,
    });
    fireFacebookCapi(FB_ELTV13_PIXEL, "ViewContent", {
      value: params.value,
      eventID: oldPixeleventID,
      currency: params.currency,
      external_id: params.userId,
    });

    const newPixelEventId = generateEventId(params.userId);
    fbq("trackSingle", META_PIXEL_ID, "ViewContent", params, {
      eventID: newPixelEventId,
    });
    fireFacebookCapi(META_PIXEL_ID, "ViewContent", {
      value: params.value,
      eventID: newPixelEventId,
      currency: params.currency,
      external_id: params.userId,
    });
  });

  registerHandler("lead", ({ emailHashes, userId, consentStatus }) => {
    const META_PIXEL_ID = getMetaPixelId();
    const eventID = generateEventId(userId);
    const newPixelEventId = generateEventId(userId);

    initAdvancedMatching(META_PIXEL_ID, userId);
    initAdvancedMatching(FB_ELTV13_PIXEL, userId);

    const fbParams: JsonObject = {
      ...consentStatus,
    };

    fbq("trackSingle", FB_ELTV13_PIXEL, "Lead", fbParams, { eventID });
    fbq("trackSingle", META_PIXEL_ID, "Lead", fbParams, { newPixelEventId });

    fireFacebookCapi(FB_ELTV13_PIXEL, "Lead", {
      customProperties: {
        ...fbParams,
      },
      external_id: userId,
      eventID,
      email: emailHashes.sha256,
    });
    fireFacebookCapi(META_PIXEL_ID, "Lead", {
      customProperties: {
        ...fbParams,
      },
      external_id: userId,
      eventID: newPixelEventId,
      email: emailHashes.sha256,
    });
  });

  registerHandler(
    "purchase",
    ({ emailHashes, order, plan, consentStatus, userId }) => {
      // refresh facebook scripts as early as possible
      reinitForMatchingParameters(FB_ELTV13_PIXEL);
      reinitForMatchingParameters(NOOM_WEIGHT_META_PIXEL);

      const eltv13Value = shouldAdjustELTV()
        ? adjustELTV(order.eltv_13_months, getCountryCode())
        : order.eltv_13_months;

      if (eltv13Value !== order.eltv_13_months) {
        trackEvent("AdjustedELTV13", {
          originalValue: order.eltv_13_months,
          adjustedValue: eltv13Value,
          adName: extractAdName(),
        });
      }

      const pixelProperties: FacebookPixelParameters = {
        ...(order.subscriptionId && { order_id: order.subscriptionId }),
        ...consentStatus,
        value: eltv13Value,
        currency: "USD",
        content_ids: plan.braintree_id,
        content_type: "product",
        content_category: "recurring",
        external_id: userId,
      };

      // legacy pixel events, to be removed after migration is complete
      const legacyStartTrialEventID = generateEventId(userId);
      // For FB algorithm optimization, we need to track both
      // Purchase and StartTrial events with identical properties.
      fbq("trackSingle", FB_ELTV13_PIXEL, "StartTrial", pixelProperties, {
        eventID: legacyStartTrialEventID,
      });
      fireFacebookCapi(FB_ELTV13_PIXEL, "StartTrial", {
        ...pixelProperties,
        eventID: legacyStartTrialEventID,
        email: emailHashes.sha256,
      });

      const legacyPurchaseEventID = generateEventId(userId);

      fbq("init", FB_ELTV13_PIXEL, {
        em: emailHashes.sha256,
      });

      fbq("trackSingle", FB_ELTV13_PIXEL, "Purchase", pixelProperties, {
        eventID: legacyPurchaseEventID,
      });
      fireFacebookCapi(FB_ELTV13_PIXEL, "Purchase", {
        ...pixelProperties,
        ...consentStatus,
        eventID: legacyPurchaseEventID,
        email: emailHashes.sha256,
      });

      const newPixelStartTrialEventID = generateEventId(userId);
      // For FB algorithm optimization, we need to track both
      // Purchase and StartTrial events with identical properties.
      fbq(
        "trackSingle",
        NOOM_WEIGHT_META_PIXEL,
        "StartTrial",
        pixelProperties,
        {
          eventID: newPixelStartTrialEventID,
        }
      );
      fireFacebookCapi(NOOM_WEIGHT_META_PIXEL, "StartTrial", {
        ...pixelProperties,
        eventID: newPixelStartTrialEventID,
        email: emailHashes.sha256,
      });

      const newPixelPurchaseEventID = generateEventId(userId);

      fbq("init", NOOM_WEIGHT_META_PIXEL, {
        em: emailHashes.sha256,
      });

      fbq("trackSingle", NOOM_WEIGHT_META_PIXEL, "Purchase", pixelProperties, {
        eventID: newPixelPurchaseEventID,
      });
      fireFacebookCapi(NOOM_WEIGHT_META_PIXEL, "Purchase", {
        ...pixelProperties,
        ...consentStatus,
        eventID: newPixelPurchaseEventID,
        email: emailHashes.sha256,
      });

      fbq("trackSingle", FB_FORBES_PIXEL, "Purchase");
    }
  );

  registerHandler(
    "purchaseIntent",
    ({ emailHashes, plan, consentStatus, userId, estimatedValue }) => {
      // refresh facebook script as early as possible
      reinitForMatchingParameters(NOOM_MED_META_PIXEL);

      const pixelProperties: FacebookPixelParameters = {
        ...consentStatus,
        value: estimatedValue || plan.plan_price,
        currency: "USD",
        content_ids: plan.braintree_id,
        content_type: "product",
        content_category: "recurring",
        external_id: userId,
      };
      const startTrialEventID = generateEventId(userId);

      // For FB algorithm optimization, we need to track both
      // Purchase and StartTrial events with identical properties.
      fbq("trackSingle", NOOM_MED_META_PIXEL, "StartTrial", pixelProperties, {
        eventID: startTrialEventID,
      });
      fireFacebookCapi(NOOM_MED_META_PIXEL, "StartTrial", {
        ...pixelProperties,
        eventID: startTrialEventID,
        email: emailHashes.sha256,
      });

      const purchaseEventID = generateEventId(userId);

      fbq("init", NOOM_MED_META_PIXEL, {
        em: emailHashes.sha256,
      });

      fbq("trackSingle", NOOM_MED_META_PIXEL, "Purchase", pixelProperties, {
        eventID: purchaseEventID,
      });
      fireFacebookCapi(NOOM_MED_META_PIXEL, "Purchase", {
        ...pixelProperties,
        ...consentStatus,
        eventID: purchaseEventID,
        email: emailHashes.sha256,
      });
    }
  );

  registerHandler(
    "trialFastForward",
    ({ discountOffered, userId, emailHashes }) => {
      const legacyPixelEventID = generateEventId(userId);

      const customProperties = {
        discountOffered,
      };
      const regularProperties = {
        external_id: userId,
        eventID: legacyPixelEventID,
      };
      const capiProperties = {
        email: emailHashes.sha256,
      };

      // Legacy events
      // Re-init the pixel
      reinitForMatchingParameters(FB_ELTV13_PIXEL);
      fbq("init", FB_ELTV13_PIXEL, {
        em: emailHashes.sha256,
      });

      const legacyPixelProperties: FacebookPixelParameters = {
        ...customProperties,
        ...regularProperties,
      };

      fbq(
        "trackSingle",
        FB_ELTV13_PIXEL,
        "TrialFastForward",
        legacyPixelProperties,
        {
          eventID: legacyPixelEventID,
        }
      );
      fireFacebookCapi(FB_ELTV13_PIXEL, "TrialFastForward", {
        ...legacyPixelProperties,
        ...capiProperties,
        customProperties,
      });

      // New pixel events
      const newPixelEventID = generateEventId(userId);
      regularProperties.eventID = newPixelEventID;
      // Re-init the pixel
      reinitForMatchingParameters(NOOM_WEIGHT_META_PIXEL);
      fbq("init", NOOM_WEIGHT_META_PIXEL, {
        em: emailHashes.sha256,
      });

      const newpixelProperties: FacebookPixelParameters = {
        ...customProperties,
        ...regularProperties,
      };

      fbq(
        "trackSingle",
        NOOM_WEIGHT_META_PIXEL,
        "TrialFastForward",
        newpixelProperties,
        {
          eventID: newPixelEventID,
        }
      );
      fireFacebookCapi(NOOM_WEIGHT_META_PIXEL, "TrialFastForward", {
        ...newpixelProperties,
        ...capiProperties,
        customProperties,
      });
    }
  );

  registerHandler(
    "noomPremium",
    ({ signupEltv13, products, userId, emailHashes }) => {
      // Legacy events
      const eventID = generateEventId(userId);
      const pixelProperties = {
        value: signupEltv13 + 40,
        external_id: userId,
      };
      fbq(
        "trackSingle",
        FB_ELTV13_PIXEL,
        "PurchasedAddOns",
        {
          ...pixelProperties,
          products,
        },
        {
          eventID,
        }
      );

      const customProperties = {
        products,
      };
      const capiProperties = {
        email: emailHashes.sha256,
        eventID,
      };

      fireFacebookCapi(FB_ELTV13_PIXEL, "PurchasedAddOns", {
        ...pixelProperties,
        ...capiProperties,
        customProperties,
      });

      // New pixel events
      const newPixelEventId = generateEventId(userId);
      fbq(
        "trackSingle",
        NOOM_WEIGHT_META_PIXEL,
        "PurchasedAddOns",
        {
          ...pixelProperties,
          products,
        },
        {
          newPixelEventId,
        }
      );

      capiProperties.eventID = newPixelEventId;

      fireFacebookCapi(NOOM_WEIGHT_META_PIXEL, "PurchasedAddOns", {
        ...pixelProperties,
        ...capiProperties,
        customProperties,
      });
    }
  );

  registerHandler("noomSummit", ({ signupEltv13, products }) => {
    fbq("trackSingle", FB_ELTV13_PIXEL, "SummitUptake", {
      value: signupEltv13 + 100,
      products,
    });
    fbq("trackSingle", NOOM_WEIGHT_META_PIXEL, "SummitUptake", {
      value: signupEltv13 + 100,
      products,
    });
  });
}

import { isUS, isDeLanguage, isEsLanguage } from "./locale";
import { routeConstants } from "../constants";
import { CoreReduxState } from "../redux/store";
import { checkSegments } from "./util";
import { getTrackingParams } from "../services/VisitTracker";

export const isEmailWinback = checkSegments({
  oneOf: [routeConstants.emailWinback],
});

export const isEmailMain = checkSegments({
  oneOf: [routeConstants.emailMain],
});

export const isMoodEmail = checkSegments({
  oneOf: [routeConstants.hmEmail],
});

export const isNoomMedClinicalRoute = checkSegments({
  oneOf: [routeConstants.clinical],
});

export const isFromEmail = checkSegments({
  oneOf: [isEmailMain, isEmailWinback, isMoodEmail],
});

export const isFromNoomWeightEmail = checkSegments({
  oneOf: [isEmailMain, isEmailWinback],
});

/** Verifies if current user comes from an email blast */
export const isEmailBlast = (state?: CoreReduxState) => {
  return isFromEmail(state) && getTrackingParams().utm_source === "blast";
};

export const isUSEmail = checkSegments({
  allOf: [isUS],
  oneOf: [isFromEmail],
});

export const isDeEmailMain = checkSegments({
  allOf: [isDeLanguage],
  oneOf: [isEmailMain],
});

export const isEsEmailMain = checkSegments({
  allOf: [isEsLanguage],
  oneOf: [isEmailMain],
});

export const isUsEmailMain = checkSegments({
  allOf: [isUS],
  oneOf: [isEmailMain],
});

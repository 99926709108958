import {
  BrowserSessionState,
  getSessionState,
  updateSessionState,
} from "src/pageDefinitions/session";
import { createForwardParams, removeURLParam } from "src/utils/urlParams";
import { ValueOf } from "type-fest";

type PrimitiveTypes = string | number | boolean;
type FeatureFlag = {
  name: keyof BrowserSessionState;
  type: PrimitiveTypes;
};

type FeatureFlagParameters = {
  [K in keyof BrowserSessionState | `f_${string}`]?: FeatureFlag;
};

declare module "src/pageDefinitions/session" {
  // FeatureFlags are ultimately a typed property on BrowserSessionState but a separate interface has been created here
  // to allow for feature flag APIs to explicitly accept arguments that are only keyof FeatureFlags rather than keyof BrowserSessionState
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface BrowserSessionState extends FeatureFlags {}
}

export interface FeatureFlags {
  togglePromoCode?: boolean;
  hiddenPromoCode?: boolean;
  allowFreeTrialOption?: boolean;
  disableLPSurvey?: boolean;
  showOriginalPrice?: boolean;
  useBaselinePriceForMultiUserPlan?: boolean;
  overrideMultiUserPlanDiscountPercentage?: number;
  enrolledInMABExperiment?: boolean;
  disableWLGModal?: boolean;
}

// * NOTE: Use documentation in feature-flags.md to learn how to create
// * and use feature flags in the buyflow
const FEATURE_FLAGS: FeatureFlagParameters = {
  togglePromoCode: {
    name: "togglePromoCode",
    type: "boolean",
  },
  f_hPC: {
    name: "hiddenPromoCode",
    type: "boolean",
  },
  f_afto: {
    name: "allowFreeTrialOption",
    type: "boolean",
  },
  f_dlps: {
    name: "disableLPSurvey",
    type: "boolean",
  },
  f_sop: {
    name: "showOriginalPrice",
    type: "boolean",
  },
  f_ubpfmup: {
    name: "useBaselinePriceForMultiUserPlan",
    type: "boolean",
  },
  f_omupdp: {
    name: "overrideMultiUserPlanDiscountPercentage",
    type: "number",
  },
  f_mab: {
    name: "enrolledInMABExperiment",
    type: "boolean",
  },
  f_dwlgm: {
    name: "disableWLGModal",
    type: "boolean",
  },
};

function convertToType(value: string, type: PrimitiveTypes) {
  switch (type) {
    case "boolean":
      return value === "true";
    case "string":
      return value;
    case "number":
      return Number(value);
    default:
      throw new Error(
        `Unsupported type for feature flag conversion: ${type}, value: ${value}`
      );
  }
}

export function loadFeatureFlags() {
  const currentUrlParams = createForwardParams();
  const featureFlagsToSet = {};
  currentUrlParams.forEach((value, key) => {
    const featureFlag: FeatureFlag = FEATURE_FLAGS[key];
    if (featureFlag) {
      featureFlagsToSet[featureFlag.name] = convertToType(
        value,
        featureFlag.type
      );
      removeURLParam(key);
    }
  });
  updateSessionState("browser", featureFlagsToSet);
}

// Create a cached list of all the defined feature flag keys
const cachedKeyList = Object.values(FEATURE_FLAGS).map((value) => value.name);

export function getFeatureFlags(): FeatureFlags {
  const sessionState = getSessionState("browser");

  const featureFlags: FeatureFlags = {};

  cachedKeyList.forEach((key) => {
    const value = sessionState[key];
    if (value !== undefined) {
      featureFlags[key] = value;
    }
  });

  return featureFlags;
}

export function setFeatureFlagValue<
  K extends ValueOf<typeof FEATURE_FLAGS>["name"]
>(key: K, value: BrowserSessionState[K]): void {
  updateSessionState("browser", { [key]: value });
}

export function getFeatureFlagValue<
  K extends ValueOf<typeof FEATURE_FLAGS>["name"]
>(key: K): BrowserSessionState[K] {
  return getSessionState("browser")[key];
}

/* eslint-disable react/jsx-props-no-spreading */
import React, { HTMLAttributes, ReactNode } from "react";
import styled from "@emotion/styled";
import { Interpolation, css } from "@emotion/react";
import { compassColors } from "src/utils/styling";
import {
  fontP4Medium,
  fontP2Regular,
  fontP4Regular,
} from "src/design-system/styles/fonts";

import {
  ErrorMessage,
  useErrorDisplay,
} from "src/components/error/ErrorMessage";
import { visuallyHidden } from "../styles/utils";
import { Text } from "src/components/core/Text";

type FieldProps = {
  label: ReactNode;
  labelStyle: "hidden" | "above";
  labelProps?: HTMLAttributes<HTMLLabelElement>;
  hasError?: boolean;
  announceError?: boolean;
  errorMessage?: ReactNode;
  errorId?: string;
  children: ReactNode;
  _container?: Interpolation<any>;
  _label?: Interpolation<any>;
  _error?: Interpolation<any>;
};

const { cinnamon, grey2, grey3, grey4, lagoon, white } = compassColors;

const LabelText = styled.span<{ labelStyle: "above" | "hidden" }>`
  ${fontP2Regular}
  display: block;
  color: ${grey4};
  margin-bottom: 8px;

  ${({ labelStyle }) => labelStyle === "hidden" && visuallyHidden};
`;

export const Error = styled(ErrorMessage)`
  ${fontP4Regular}
  color: ${cinnamon};
  margin: 0;
  width: 100%;
`;

const SuccessContainer = styled.div`
  text-align: left;
  width: 100%;
`;

const SuccessHeader = styled.div`
  ${fontP4Medium}
  color: ${lagoon};
`;

const SuccessBody = styled.div`
  ${fontP4Regular}
  color: ${grey3};
`;

export function Success({ header, body }: { header?: string; body?: string }) {
  return (
    <SuccessContainer>
      {header && (
        <SuccessHeader>
          <Text i18nKeyOrText={header} />
        </SuccessHeader>
      )}
      {body && (
        <SuccessBody>
          <Text i18nKeyOrText={body} />
        </SuccessBody>
      )}
    </SuccessContainer>
  );
}

export function Field({
  label,
  labelProps,
  labelStyle = "hidden",
  hasError = false,
  errorMessage,
  _container,
  _label,
  _error,
  children,
  announceError,
  errorId,
}: FieldProps) {
  // Focus on the whole field when the error message is thrown
  const { ref } = useErrorDisplay({ errored: hasError });

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label css={[{ width: "100%" }, _container]} ref={ref} {...labelProps}>
        <LabelText labelStyle={labelStyle} css={_label}>
          {label}
        </LabelText>
        {children}
      </label>
      {hasError && errorMessage && (
        <Error announceError={announceError} errorId={errorId} css={_error}>
          {errorMessage}
        </Error>
      )}
    </>
  );
}

/**
 * Provides the border, background, etc styles for fields wishing to
 * match the styling of the text input. This can be used for custom components
 * that require custom class injection or to be wrapped in a wrapper element for
 * styling.
 */
export const inputBoxStyles = ({ hasError }: { hasError: boolean }) => css`
  ${fontP2Regular}
  background-color: ${white};
  border-radius: 8px;
  box-sizing: border-box;
  flex: 1;
  height: 62px;
  outline: 0;
  width: 100%;
  ${hasError
    ? css`
        border: solid 2px ${cinnamon};
        padding: 16px 18px;
      `
    : css`
        border: solid 1px ${grey2};
        padding: 17px 19px;
      `}

  &::placeholder {
    color: ${grey2};
  }

  &:focus {
    border: 1px solid ${lagoon};
    padding: 17px 19px;
  }
  &:disabled {
    border: 1px solid #cacaca;
    color: #4d4d4d;
    background: #e2e2e2;
  }
`;

import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useSelector, useStore } from "react-redux";
import {
  Direction,
  MeristemClientConfig,
  Page,
  PageSet,
} from "src/pageDefinitions/types";
import {
  getPageSetForLayer,
  getPageAtLocation,
  getActivePage,
} from "src/pageDefinitions/pageSets";
import { CoreReduxState } from "@utils/redux/store";

import goto from "src/pageDefinitions/goto";

import {
  ensureGotoSetup,
  gotoPage,
  GoToPageOptions,
} from "src/pageDefinitions/goto/pageSet";
import { useAsyncError } from "./error";
import { preloadPagePath } from "src/router";
import { useCallbackRef, useOnce } from "./lifecycle";
import { captureException } from "src/utils/error";
import {
  PageSetEventHandler,
  addPageSetEventHandler,
} from "src/pageDefinitions/pageSets/bus";
import {
  findFuturePageIndex,
  resolvePage,
} from "src/pageDefinitions/pageSets/eval";
import {
  clearActivePageSet,
  setActivePageSet,
} from "src/utils/redux/slices/linearBuyflow";
import { resolveParamPageSet } from "src/pageDefinitions/pageSets/init";
import { useAppDispatch } from "./redux";
import { useHistory, useLocation } from "react-router";
import invariant from "tiny-invariant";
import {
  BrowserSessionState,
  getSessionState,
  PageSetSessionState,
  updateSessionState,
} from "src/pageDefinitions/session";
import { appConfig } from "src/config";
import { PageReplaceProvider } from "./page-replace";
import {
  ensurePageSetHistoryState,
  PageSetHistoryState,
} from "src/pageDefinitions/components/PageSetRouter";
import { findEntryPointLocation } from "src/pageDefinitions/actions/survey/util";

/**
 * Allows ancestors of the usePageSet hook manage how errors are handled.
 * This is mostly used as DI for testing.
 */
const PageSetErrorContext = createContext<(err: Error) => void>(null);

/**
 * Blocks until we are able to fully load the current pageset
 */
export function PageSetGate({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch();
  const location = useLocation<PageSetHistoryState>();

  const [loaded, setLoaded] = useState(false);
  const store = useStore();

  // Handle pageset matching on boot
  useOnce(() => {
    (async () => {
      const state = store.getState();

      // Attempt to load the pageset from the url, when possible as this will be the most correct.
      const paramPageSet = await resolveParamPageSet(state, location);
      if (paramPageSet && paramPageSet.id !== state.linearBuyflow.pageSetName) {
        // If we are on the initial entry point, ensure that we correct history state
        ensurePageSetHistoryState(paramPageSet);

        await dispatch(setActivePageSet(paramPageSet));
      }
      setLoaded(true);
    })();
  });

  const handlePageSetError = useCallback(
    (error: Error) => {
      dispatch(clearActivePageSet());

      captureException(error);

      /* istanbul ignore next */
      if (__NODE_ENV__ !== "test") {
        // eslint-disable-next-line no-debugger
        debugger;
      }

      goto.bail("landing", [], error.message);
    },
    [dispatch]
  );

  if (!loaded) {
    return null;
  }

  return (
    <PageSetErrorContext.Provider value={handlePageSetError}>
      <PageReplaceProvider>{children}</PageReplaceProvider>
    </PageSetErrorContext.Provider>
  );
}

export function useGotoOptions(): GoToPageOptions {
  const history = useHistory<PageSetHistoryState>();
  const dispatch = useAppDispatch();
  const store = useStore<CoreReduxState>();
  return useMemo(
    () => ({
      history,
      dispatch,
      store,
    }),
    [history, dispatch, store]
  );
}

// TODO: Heavily document this. Why would you use it. What gotchas are there?
export function usePageSetEvents(cb: PageSetEventHandler) {
  const cbRef = useCallbackRef(cb);

  return useOnce(() => {
    return addPageSetEventHandler(cbRef);
  });
}

function preloadFuturePage(
  gotoOptions: GoToPageOptions & {
    activePageSet: PageSet;
  }
) {
  const { activePageSet, store } = gotoOptions;
  const futurePageIndex = findFuturePageIndex(
    Direction.FORWARD,
    activePageSet,
    gotoOptions
  );
  const futurePage = getPageAtLocation(activePageSet, futurePageIndex);
  if (futurePage) {
    const resolvedPage = resolvePage(
      futurePage,
      store.getState(),
      Direction.FORWARD
    );

    if (resolvedPage && "pathname" in resolvedPage) {
      preloadPagePath(resolvedPage.pathname);
    }
  }
}

addPageSetEventHandler((event, eventParams) => {
  if (event === "after:goto") {
    preloadFuturePage(eventParams);
  }
});

export function usePageSetPreload(layer: string) {
  const gotoOptions = useGotoOptions();
  const state = useSelector((param: CoreReduxState) => param);

  useOnce(() => {
    getPageSetForLayer(layer, state).then((pageSet) => {
      if (pageSet) {
        preloadFuturePage({
          activePageSet: pageSet,
          ...gotoOptions,
        });
      }
    });
  });
}

export type PageSetProps = {
  activePage: Page;
  activePageSet: PageSet;

  browserSession: BrowserSessionState;
  pageSetSession: PageSetSessionState;
  meristemConfig: MeristemClientConfig;

  updatePageSetSession: (newValues: PageSetSessionState) => void;

  gotoNextPage: (replace?: boolean) => Promise<void>;
  gotoPrevPage: (replace?: boolean) => Promise<void>;
  goToEntryPoint: (entryPoint: string, replace?: boolean) => Promise<void>;
};

/**
 * Linear buyflow framework that wraps pool pages and handles users moving forward
 * and backwards.
 */
export function usePageSet(ignoreMissing = false): PageSetProps {
  const gotoOptions = useGotoOptions();
  const onPageSetError = useContext(PageSetErrorContext);

  const location = useLocation<{ pageSetId: string }>();

  // Can be removed after nested routers are sunsetted
  // onPageSetError ensure that we don't fail in storybook
  if (__NODE_ENV__ === "development" && onPageSetError) {
    invariant(
      (gotoOptions.history as any).type === "pageSet",
      "usePageSet should only be used with the pageset router"
    );
  }

  const { activePageSet, activePage } = getActivePage(
    location.pathname,
    gotoOptions.store.getState(),
    location
  );

  useOnce(() => {
    if (activePage) {
      ensureGotoSetup(activePageSet, activePage, gotoOptions);
      return;
    }

    if (!ignoreMissing) {
      if (!activePageSet) {
        onPageSetError?.(new Error("No active page set"));
      } else {
        onPageSetError?.(
          new Error(`No active page found pageSet: ${activePageSet.id}`)
        );
      }
    }
  });

  const asyncError = useAsyncError();

  return useMemo(
    () => ({
      activePage,
      activePageSet,

      pageSetSession: getSessionState("pageSet", activePageSet?.layer),
      browserSession: getSessionState("browser"),
      meristemConfig: appConfig.meristemConfig,

      updatePageSetSession: (newValues: PageSetSessionState) => {
        updateSessionState("pageSet", activePageSet.layer, newValues);
      },

      gotoNextPage: async (replace?: boolean) => {
        invariant(
          replace == null || typeof replace === "boolean",
          "replace must be a boolean"
        );
        return gotoPage(activePageSet, gotoOptions, replace).catch(asyncError);
      },
      gotoPrevPage: async () => {
        gotoOptions.history.goBack();
      },
      goToEntryPoint: async (entryPoint: string, replace?: boolean) => {
        invariant(
          replace == null || typeof replace === "boolean",
          "replace must be a boolean"
        );
        const entryPointLocation = findEntryPointLocation(
          activePageSet,
          entryPoint
        );
        return gotoPage(
          activePageSet,
          gotoOptions,
          replace,
          entryPointLocation
        ).catch(asyncError);
      },
    }),
    [activePage, activePageSet, gotoOptions, asyncError]
  );
}

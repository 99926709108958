import styled from "@emotion/styled";
import React, { ButtonHTMLAttributes, ReactNode, useMemo } from "react";
import { compassColors } from "@utils/styling";
import { fontP2Medium, fontP2Regular } from "src/design-system/styles/fonts";
import { flexMiddle } from "src/design-system/styles/layout";
import { focusOutline } from "src/design-system/styles/utils";

export const GenericButton = styled.button`
  ${flexMiddle};
  ${fontP2Medium}
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 16px;
  min-height: 60px;
  width: 100%;
  cursor: pointer;
  text-align: center;
`;

export type ButtonDesignStyle =
  | "primaryCta"
  | "secondaryCta"
  | "singleSelect"
  | "premiumPrimaryCta"
  | "tertiaryCta"
  | "medPrimaryCta";

const PrimaryCtaButton = styled(GenericButton, {
  shouldForwardProp: (prop) => prop !== "borderRadius",
})<{ borderRadius?: string }>`
  background-color: ${compassColors.tarocco};
  color: ${compassColors.white};

  &:hover {
    background-color: ${compassColors.cinnamon};
  }

  &:disabled {
    background-color: ${compassColors.grey2};
  }

  &:focus {
    border: solid 2px ${compassColors.cinnamon};
    outline: 0;
    ${focusOutline}
  }

  ${(props) =>
    props.borderRadius
      ? `
          border-radius: ${props.borderRadius};
        `
      : ``}
`;

const MedPrimaryCtaButton = styled(Button)<Props>`
  ${({ borderRadius }) => `border-radius: ${borderRadius ?? "100px"};`}
  background-color: ${compassColors.lagoon};
  &:hover {
    background-color: ${compassColors.lagoon};
  }
  &:disabled {
    background-color: ${compassColors.grey2};
  }
  &:focus {
    border: solid 0px ${compassColors.lagoon};
    background-color: ${compassColors.lagoon};
    outline: 0;
    ${focusOutline}
  }
`;

const PremiumPrimaryCtaButton = styled(PrimaryCtaButton)`
  background-color: ${compassColors.gold};

  &:hover {
    background-color: ${compassColors.premiumBrown};
  }

  &:focus {
    border: solid 2px ${compassColors.premiumBrown};
  }
`;

const SecondaryCta = styled(GenericButton)`
  background-color: ${compassColors.white};
  color: ${compassColors.grey4};

  &:hover:enabled {
    background-color: ${compassColors.offWhite};
    color: ${compassColors.tarocco};
  }

  &:disabled {
    color: ${compassColors.grey2};
  }

  &:focus {
    border: solid 2px ${compassColors.grey2};
    outline: 0;
    ${focusOutline}
  }
`;

const TertiaryCta = styled(GenericButton)`
  border-radius: 100px;
  background-color: ${compassColors.lagoon};
  color: ${compassColors.white};

  &:hover {
    background-color: ${compassColors.lagoonShade1};
  }

  &:disabled {
    background-color: ${compassColors.grey2};
  }

  &:focus {
    border: solid 2px ${compassColors.lagoonShade1};
    outline: 0;
    ${focusOutline}
  }
`;

const SingleSelectButton = styled(GenericButton)<{ isSelected: boolean }>`
  ${fontP2Regular}
  background-color: ${compassColors.offWhite};
  // Add a border the same color as the background so that the visible 2px focus border
  // doesn't change the width of the Button, which was happening with Score questions.
  border: solid 2px ${compassColors.offWhite};
  color: ${compassColors.black};

  &:disabled {
    opacity: 0.75;
  }

  ${(props) =>
    props.isSelected
      ? `
        background-color: ${compassColors.lagoon};
        border: solid 2px ${compassColors.lagoon};
        color: ${compassColors.offWhite};

        &:focus {
          border: solid 2px ${compassColors.blueberry};
          outline: 0;
        }
        `
      : `
        &:hover:enabled {
          background-color: ${compassColors.sand1};
          border: solid 2px ${compassColors.sand1};
        }

        &:focus {
          border: solid 2px ${compassColors.gold};
          outline: 0;
        }
        `}

  &:focus {
    ${focusOutline}
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Icon = styled.img<{ height?: string; margin?: Margin }>`
  ${(props) => `height: ${props.height || "18px"};`}
  ${(props) =>
    `margin: ${props.margin?.top || "auto"} ${props.margin?.right || "0"} ${
      props.margin?.bottom || "auto"
    } ${props.margin?.left || "0"};`}
`;

export type Props = {
  designStyle?: ButtonDesignStyle;
  isSelected?: boolean;
  icon?: IconInfo;
  borderRadius?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

type IconInfo = {
  src: string;
  height?: string;
  margin?: Margin;
  position?: "left" | "right";
};

type Margin = {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
};

function Button({
  designStyle = "primaryCta",
  isSelected,
  icon,
  children: childrenProp,
  borderRadius,
  ...buttonProps
}: Props) {
  const children = useIcon(icon, childrenProp);

  if (designStyle === "singleSelect") {
    return (
      <SingleSelectButton
        type="button"
        isSelected={isSelected}
        {...buttonProps}
      >
        {children}
      </SingleSelectButton>
    );
  }

  if (designStyle === "secondaryCta") {
    return (
      <SecondaryCta type="button" {...buttonProps}>
        {children}
      </SecondaryCta>
    );
  }

  if (designStyle === "tertiaryCta") {
    return (
      <TertiaryCta type="button" {...buttonProps}>
        {children}
      </TertiaryCta>
    );
  }

  if (designStyle === "premiumPrimaryCta") {
    return (
      <PremiumPrimaryCtaButton type="button" {...buttonProps}>
        {children}
      </PremiumPrimaryCtaButton>
    );
  }

  if (designStyle === "medPrimaryCta") {
    return (
      <MedPrimaryCtaButton
        type="button"
        borderRadius={borderRadius}
        {...buttonProps}
      >
        {children}
      </MedPrimaryCtaButton>
    );
  }

  return (
    <PrimaryCtaButton
      type="button"
      {...buttonProps}
      borderRadius={borderRadius}
    >
      {children}
    </PrimaryCtaButton>
  );
}

function useIcon(icon?: IconInfo, children?: ReactNode) {
  return useMemo(() => {
    // Wrap children around an icon container if needed
    if (icon) {
      const { src, height, margin, position = "left" } = icon;
      const iconElement = (
        <Icon
          src={src}
          height={height}
          margin={margin}
          alt=""
          role="presentation"
        />
      );
      return position === "left" ? (
        <IconContainer>
          {iconElement}
          {children}
        </IconContainer>
      ) : (
        <IconContainer>
          {children}
          {iconElement}
        </IconContainer>
      );
    }

    return children;
  }, [children, icon]);
}

export default Button;

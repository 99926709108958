import { getInAppAutoCookie } from "../authCookieParser";
import { sendBeacon } from "../fetch";
import { addMonitoringEventHandler } from "./bus";
import uuid from "uuid";
import { appConfig } from "src/config";
import { MMPEventProto_SharedPropertiesProto } from "@noom/noom-contracts/noom_contracts/events/mobile_measurement_partner/mmp_event";
import getStore from "../redux/store";
import { singularSdk } from "singular-sdk";
import { getRequestMetadata } from "../meristemContext";

addMonitoringEventHandler((event) => {
  if (event.type === "mmpEvent") {
    sendMmpEvent(event.eventName, event.properties);
  }
});

// Note (Pedro): here we want different values for when the user is coming through
// a webview (has the in-app cookie set from mobile) or through a regular web page,
// which then should use info from the Singular SDK & related sources.
function buildSharedProperties() {
  const mmpContext = getInAppAutoCookie();

  const platformMap = {
    WEB: "WEB",
    IOS: "IOS",
    ANDROID: "ANDROID",
  };

  let sessionId;
  if (mmpContext) {
    sessionId = undefined;
  } else if (appConfig.NOOM_ENV === "production") {
    sessionId = singularSdk.getSingularDeviceId();
  } else {
    sessionId = "DFC5A647-9043-4699-B2A5-76F03A97064B";
  }

  const { userIp } = getRequestMetadata();

  const props: MMPEventProto_SharedPropertiesProto = {
    customUserId: mmpContext?.customUserId ?? mmpContext?.accessCode,
    growthUserId: getStore().getState().userData.user_id,
    platform: mmpContext?.platform
      ? platformMap[mmpContext.platform.toUpperCase()]
      : "WEB",
    packageId: mmpContext?.packageId ?? appConfig.SINGULAR_SDK_PRODUCT_ID,
    ip: mmpContext?.deviceIP ?? userIp ?? "0.0.0.0", // Note(Pedro): default to 0.0.0.0 just so the event doesn't fail since it's a required field
    googleAdId:
      mmpContext?.platform.toUpperCase() === "ANDROID"
        ? mmpContext.googleAdId
        : undefined,
    iosAdId:
      mmpContext?.platform.toUpperCase() === "IOS"
        ? mmpContext.iosAdId
        : undefined,
    amazonAdId:
      mmpContext?.platform.toUpperCase() === "ANDROID"
        ? mmpContext.amazonAdId
        : undefined,
    openAdId:
      mmpContext?.platform.toUpperCase() === "ANDROID"
        ? mmpContext.openAdId
        : undefined,
    appSetId:
      mmpContext?.platform.toUpperCase() === "ANDROID"
        ? mmpContext.appSetId
        : undefined,
    identifierForVendor:
      mmpContext?.platform.toUpperCase() === "IOS"
        ? mmpContext.idForVendor
        : undefined,
    sessionId,
    osVersion: mmpContext?.osVersion,
    attAuthorizationStatus:
      mmpContext?.platform.toUpperCase() === "IOS"
        ? Number(mmpContext.attStatus)
        : undefined,
    limitDataSharing:
      mmpContext?.limitDataSharing === "true" ? true : undefined,
  };

  return props;
}

async function sendMmpEvent(eventName: string, properties: JsonObject) {
  const uncapitalize = (str: string) =>
    str.charAt(0).toLocaleLowerCase() + str.slice(1);
  const eventNameKey = uncapitalize(eventName);
  const sharedProperties = await buildSharedProperties();

  sendBeacon("/pixel/v1/i/dataStreamEventTracking/", {
    sharedProperties: {
      eventTimestamp: new Date(),
      eventUuid: uuid.v4(),
      blockRoutingToMixpanel: true,
    },
    mmpEvent: {
      [eventNameKey]: {
        ...properties,
      },
      sharedProperties,
    },
  });
}

import React, { ReactNode } from "react";
import styled from "@emotion/styled";

import { DEFAULT_COLUMN_MARGIN, MainColumn } from "../core/BodyLayout";
import { Avatar } from "./Avatar";
import { fontTitle } from "src/design-system/styles/fonts";
import { textTruncate } from "src/design-system/styles/utils";
import { HeaderToolbar } from "../core/HeaderToolbar";
import { useUserData } from "src/hooks/redux";
import { useSurveyAnswers } from "src/hooks/survey/answers";
import { useSelector } from "react-redux";
import type { CoreReduxState } from "src/utils/redux/internal";
import { css } from "@emotion/react";
import PaperTexture from "./images/paperTexture.webp";

type Props = {
  children?: ReactNode;
  headerBackgroundColor?: string;
  headerTextColor?: string;
  hasHeaderBorder?: boolean;
};

// Default column width used when not nested within a MainColumn element.
const AVATAR_COLUMN_WIDTH = "780px";

const MAIN_COLUMN_WIDTH = `var(--main-column-width, ${AVATAR_COLUMN_WIDTH})`;
const MAIN_COLUMN_MARGIN = `var(--main-column-margin, ${DEFAULT_COLUMN_MARGIN})`;

const Column = styled(MainColumn)`
  height: 50px;

  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 16px;
  align-items: center;

  // This lovely thing pushes the avatar to the right to give that
  // a 780 column width alignment while keeping the left child on
  // the current main column alignment.
  --header-margin: max(
    0px,
    (100vw - ${MAIN_COLUMN_WIDTH}) / 2 - ${MAIN_COLUMN_MARGIN}
  );
  margin-left: var(--header-margin);
  max-width: min(
    100vw - var(--header-margin) - ${MAIN_COLUMN_MARGIN} * 2,
    ${MAIN_COLUMN_WIDTH} + (${AVATAR_COLUMN_WIDTH} - ${MAIN_COLUMN_WIDTH}) / 2
  );
`;

const Email = styled.div`
  ${fontTitle};
  ${textTruncate};
  text-align: right;
`;

const headerStyles = (headerBackground: string, headerBorder: boolean) => css`
  ${headerBorder === false && `border: none`};
  ${headerBackground &&
  `background-color: ${headerBackground};
     background-image: url(${PaperTexture});`};
`;

export default function LoggedInHeader({
  headerBackgroundColor,
  headerTextColor,
  hasHeaderBorder,
  children,
}: Props) {
  const userData = useUserData();

  // Loading directly rather than using useEnrollment hook to avoid pulling in
  // the rather large intl tel validation package across the entire app.
  const { enrollmentInfo } = useSelector(
    (state: CoreReduxState) => state.paymentEnrollmentForm
  );

  const { firstName } = useSurveyAnswers();
  const email = enrollmentInfo.email || userData.email || "Noom";

  return (
    <nav>
      <HeaderToolbar css={headerStyles(headerBackgroundColor, hasHeaderBorder)}>
        <Column>
          {children}
          <Email css={{ gridColumn: 2, color: headerTextColor }}>
            {firstName || email}
          </Email>
          <Avatar css={{ gridColumn: 3 }} email={firstName || email} />
        </Column>
      </HeaderToolbar>
    </nav>
  );
}

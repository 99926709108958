import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseThunkAPI } from "@reduxjs/toolkit/dist/createAsyncThunk";
import { AppDispatch, CoreReduxState } from "./store";

// Note that we are using our own payloadCreator type here vs. the one provided by @reduxjs/toolkit
// To allow for inference of params and return, which doesn't occur with the provided type.
type PayloadCreator<Params, Returned> = (
  arg: Params,
  thunkAPI: BaseThunkAPI<CoreReduxState, void, AppDispatch>
) => Promise<Returned>;

/**
 * Provides a typed wrapper for createAsyncThunk, typed to our app and utilizing
 * type inference for parameters and return values.
 */
export function createAppAsyncThunk<Params, Returned>(
  typePrefix: string,
  payloadCreator: PayloadCreator<Params, Returned>
) {
  return createAsyncThunk<
    Returned,
    Params,
    {
      dispatch: AppDispatch;
      state: CoreReduxState;
    }
  >(typePrefix, payloadCreator);
}

import { trackBuyflowEvent } from "src/utils/api/tracker";
import {
  getSessionState,
  updateSessionState,
} from "src/pageDefinitions/session";

/* These constants are also passed in by pageset actions as hardcoded strings, so please ensure those are updated if you change the values here */
export const IDEAL_WEIGHT_VIBE_OFFER_ID = "idealWeightOffer";
export const STEP_TRACKING_VIBE_OFFER_ID = "stepTrackingOffer";

export type NoomVibeOfferId =
  | typeof IDEAL_WEIGHT_VIBE_OFFER_ID
  | typeof STEP_TRACKING_VIBE_OFFER_ID;

declare module "src/pageDefinitions/session" {
  interface BrowserSessionState {
    noomVibeOfferViewed?: {
      offerId: string;
    };
  }
}

/* Only show noom vibe offer pages if users have not previously seen a different noom vibe offer page */
export function shouldSuppressVibeOffer(offerId: string) {
  const { noomVibeOfferViewed } = getSessionState("browser");
  return (
    noomVibeOfferViewed?.offerId && noomVibeOfferViewed?.offerId !== offerId
  );
}

export function trackNoomVibeOfferViewed(offerId: string) {
  trackBuyflowEvent("NoomVibeOfferViewed", {
    offerId,
  });
  updateSessionState("browser", {
    noomVibeOfferViewed: {
      offerId,
    },
  });
}

export function trackNoomVibeOfferSuppressed(offerId: string) {
  trackBuyflowEvent("NoomVibeOfferSuppressed", {
    offerId,
  });
}

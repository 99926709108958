/**
 * Implements the primary redux store.
 *
 * Note that this has been separated from src/utils/redux/store.ts to allow for
 * indirect, deferred, loading of this file. This helps us avoid init time circular
 * dependencies, reducing some possible startup errors and allowing for much easier
 * testing of the redux store and it's components.
 */
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import addOnsSlice from "./slices/addOns";
import checkoutSlice from "./slices/checkout";
import serverContextSlice from "./slices/serverContext";
import plansSlice from "./slices/plans";
import recommendedPlansSlice from "./slices/recommendedPlan";
import surveyAnswersSlice from "./slices/surveyAnswers";
import currentAnswersSlice from "./slices/currentAnswers";
import trialSlice from "./slices/trial";
import surveyNameSpaceSlice from "./slices/surveyNameSpace";
import userDataReducer from "./slices/userData";
import visitorStatusSlice from "./slices/visitorStatus";
import paymentEnrollmentFormSlice from "./slices/paymentEnrollmentForm";
import linearBuyflowSlice from "./slices/linearBuyflow";
import surveyProgressSlice from "./slices/surveyProgress";
import questionHistorySlice from "./slices/questionHistory";
import activeSubscriptionDataSlice from "./slices/activeSubscriptionData";
import upidSlice from "./slices/upid";
import winbackInfoSlice from "./slices/winbackInfo";
import offersReducer from "./slices/offers";

import { rehydrateStore } from "./persistence";
import programSwitchErrorDetailsSlice from "./slices/programSwitchErrorDetails";
import promoCodeSlice from "./slices/promoCode";
import paymentAddonSlice from "./slices/paymentAddon";
import planOptionsSlice from "./slices/planOptions";
import counterOffer from "./slices/counterOffer";
import commitmentContractOptInSlice from "./slices/commitmentContractOptIn";
import multiUserPlanSlice from "./slices/multiUserPlan";
import checkoutPhysicalAddressSlice from "./slices/checkoutPhysicalAddress/checkoutPhysicalAddress";
import fallbackPromotionalOfferSlice from "./slices/fallbackPromotionalOffer";
import purchasedNoomClinicalSlice from "./slices/purchasedNoomClinical";
import animationStateSlice from "./slices/animationsState";
import premiumCounterofferSlice from "./slices/premiumCounteroffer";
import authStatusSlice from "./slices/authStatus";
import kudosSlice from "@utils/redux/slices/kudos";
import persistentBrowserFlagsSlice from "./slices/persistentBrowserFlags";
import medEntitlementsSlice from "./slices/medEntitlements";

export const rootReducer = combineReducers({
  serverContext: serverContextSlice.reducer,
  checkout: checkoutSlice.reducer,
  checkoutPhysicalAddress: checkoutPhysicalAddressSlice.reducer,
  plans: plansSlice.reducer,
  recommendedPlan: recommendedPlansSlice.reducer,
  surveyAnswers: surveyAnswersSlice.reducer,
  currentAnswers: currentAnswersSlice.reducer,
  trial: trialSlice.reducer,
  userData: userDataReducer,
  surveyNameSpace: surveyNameSpaceSlice.reducer,
  visitorStatus: visitorStatusSlice.reducer,
  paymentEnrollmentForm: paymentEnrollmentFormSlice.reducer,
  linearBuyflow: linearBuyflowSlice.reducer,
  surveyProgress: surveyProgressSlice.reducer,
  questionHistory: questionHistorySlice.reducer,
  activeSubscriptionData: activeSubscriptionDataSlice.reducer,
  upid: upidSlice.reducer,
  programSwitchErrorDetails: programSwitchErrorDetailsSlice.reducer,
  offers: offersReducer,
  promoCode: promoCodeSlice.reducer,
  winbackInfo: winbackInfoSlice.reducer,
  paymentAddon: paymentAddonSlice.reducer,
  planOptions: planOptionsSlice.reducer,
  counterOffer: counterOffer.reducer,
  commitmentContractOptIn: commitmentContractOptInSlice.reducer,
  multiUserPlan: multiUserPlanSlice.reducer,
  fallbackPromotionalOffer: fallbackPromotionalOfferSlice.reducer,
  purchasedNoomClinical: purchasedNoomClinicalSlice.reducer,
  addOns: addOnsSlice.reducer,
  animationsState: animationStateSlice.reducer,
  premiumCounteroffer: premiumCounterofferSlice.reducer,
  authStatus: authStatusSlice.reducer,
  kudos: kudosSlice.reducer,
  persistentBrowserFlags: persistentBrowserFlagsSlice.reducer,
  medEntitlements: medEntitlementsSlice.reducer,
});

export type ReducerStore = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  preloadedState: rehydrateStore(),
});

/**
 * WARN: This should only be imported by the getStore method. Direct imports will
 * potentially cause circular dependencies.
 */
export const dangerousInternalStore = store;

export type CoreReduxStore = typeof store;
export type CoreReduxState = ReturnType<typeof store.getState>;
export type GetAppState = typeof store.getState;
export type AppDispatch = typeof store.dispatch;

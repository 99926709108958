import type { MeristemClientConfig } from "./pageDefinitions/types";

// Note explicitly not loading the timing module here to avoid init overhead
// as well as import cascades.
if (window.performance?.mark) {
  window.performance.mark("bf-exec-start");
}

// eslint-disable-next-line no-underscore-dangle
declare const __GROW_CONFIG__: Record<string, string>;

const injectedConfig = __GROW_CONFIG__;

/**
 * Template/HTML injections
 */

type NodeEnv = "production" | "staging" | "development" | "local" | "test";

const noomEnv = (injectedConfig.NOOM_ENV as NodeEnv) || "local";

export const appConfig = {
  NOOM_ENV: noomEnv,

  // Third-party *template* variables
  MIXPANEL_PROJECT_TOKEN: injectedConfig.MIXPANEL_PROJECT_TOKEN,
  ONE_TRUST_DOMAIN_ID: injectedConfig.ONE_TRUST_DOMAIN_ID,
  TALKABLE_SITE_ID: noomEnv === "production" ? "noom" : "noom-staging",
  API_DOMAIN: injectedConfig.API_DOMAIN || origin,
  COACH_SERVER_URL: injectedConfig.COACH_SERVER_URL,
  USERPORTAL_URL:
    noomEnv === "production"
      ? "https://account.noom.com/"
      : "https://account.test.wsli.dev/",
  PATIENT_PORTAL_URL:
    noomEnv === "production"
      ? "https://healthcare.noom.com/"
      : "https://health.test.wsli.dev/",
  SENTRY_PROJECT_DSN_JS: injectedConfig.SENTRY_PROJECT_DSN_JS,
  BRAINTREE_TOKENIZATION_KEY: injectedConfig.BRAINTREE_TOKENIZATION_KEY,
  STRIPE_API_KEY: injectedConfig.STRIPE_API_KEY,
  GOOGLE_MAPS_API_KEY: injectedConfig.GOOGLE_MAPS_API_KEY,
  RECAPTCHA_SITE_KEY: injectedConfig.RECAPTCHA_SITE_KEY,
  // Fallback to wp domain for localhost, proxied on other domains
  BLOG_DOMAIN: noomEnv === "local" ? "http://webnoomdev.wpengine.com" : "",

  MED_PIXEL_TRACKING_ENABLED:
    injectedConfig.MED_PIXEL_TRACKING_ENABLED === "true",
  TELEHEALTH_BUYFLOW_THROTTLE: parseFloat(
    injectedConfig.TELEHEALTH_BUYFLOW_THROTTLE
  ),
  USE_SESSION_STORAGE_FOR_PLAN_TEMPORARY_FLAG:
    injectedConfig.USE_SESSION_STORAGE_FOR_PLAN_TEMPORARY_FLAG === "true",
  PUBLISH_TO_USER_MODEL_ENABLED:
    injectedConfig.PUBLISH_TO_USER_MODEL_ENABLED === "true",
  PROBIOTICS_DELIVERY_ENABLED:
    injectedConfig.PROBIOTICS_DELIVERY_ENABLED === "true",
  REFERRAL_LINK_FLOW_ENABLED:
    injectedConfig.REFERRAL_LINK_FLOW_ENABLED === "true",
  ZUMBA_OFFER_ENABLED: injectedConfig.ZUMBA_OFFER_ENABLED === "true",
  NMC_PRODUCT_VARIANT_ID: injectedConfig.NMC_PRODUCT_VARIANT_ID,
  MED_ORALS_ENABLED: injectedConfig.MED_ORALS_ENABLED === "true",
  MED_ORALS_BUYFLOW_TRAFFIC_SPLIT: parseFloat(
    injectedConfig.MED_ORALS_BUYFLOW_TRAFFIC_SPLIT
  ),
  MED_ORALS_HOLDBACK_TRAFFIC: parseFloat(
    injectedConfig.MED_ORALS_HOLDBACK_TRAFFIC
  ),
  SINGULAR_SDK_KEY: injectedConfig.SINGULAR_SDK_KEY,
  SINGULAR_SDK_SECRET: injectedConfig.SINGULAR_SDK_SECRET,
  SINGULAR_SDK_PRODUCT_ID: injectedConfig.SINGULAR_SDK_PRODUCT_ID,

  meristemConfig: (injectedConfig.meristemConfig || {}) as MeristemClientConfig,
} as const;

import pick from "lodash/pick";

import { SurveyAnswersState } from "../redux/slices/surveyAnswers";
import { RecommendedPlanState } from "../redux/slices/recommendedPlan";
import { UserDataState } from "../redux/slices/userData";

import {
  fireCallbackWithRetry,
  PIXEL_RETRY_DELAY_MS,
  PIXEL_RETRY_MAX_ATTEMPTS,
} from "./utils";
import { typedKeys } from "../typeWrappers";
import { toSha1, toSha256 } from "../cryptography";
import md5 from "md5";
import { PromoCodeState } from "../redux/slices/promoCode";
import { Opaque } from "type-fest";
import { PurchaseProgramResponse } from "../api/purchase/checkout";
import { bootstrapConsent } from "../consent";

type LeadArgs = {
  email: string;
  surveyAnswers?: SurveyAnswersState;
  userData: UserDataState;
};

type PurchaseArgs = {
  email: string;
  plan: RecommendedPlanState;
  surveyAnswers: SurveyAnswersState;
  order: PurchaseProgramResponse;
  userData: UserDataState;
  promoCode: PromoCodeState;
};

type FastForwardTrialArgs = {
  discountOffered: boolean;
  email: string;
  userData: UserDataState;
};

type NoomPremiumArgs = {
  signupEltv13: number;
  products: string[];
  email: string;
  userData: UserDataState;
};

// This is the list of survey answers that are allowed to be sent to pixel handlers. All other
// survey answers will be filtered out.
// Before adding to this list, please consult with legal.
const surveyAnswersAllowlist = [
  "gdpr",
  "gdprConsent",
  "pendingDoubleOptin",
] as const;

// Since the only keys allowed are related to consent, naming this to be more clear to users.
// If more keys are added to the allowlist in the future, consider renaming to something like
// `CleanSurveyAnswers`.
type ConsentStatus = Pick<
  SurveyAnswersState,
  typeof surveyAnswersAllowlist[number]
>;

// This is the list of order detail keys that are allowed to be sent to pixel handlers. All other
// keys will be filtered out.
// Before adding to this list, please consult with legal.
const orderDetailsAllowlist = [
  "eltv_13_months",
  "subscriptionId",
  "user_id",
  "upid",
] as const;

type CleanOrderDetails = Pick<
  PurchaseProgramResponse,
  typeof orderDetailsAllowlist[number]
>;

export type Sha256Email = Opaque<string, "sha256">;
export type Sha1Email = Opaque<string, "sha1">;
export type Md5Email = Opaque<string, "md5">;

type EmailHashes = {
  sha1: Sha1Email;
  md5: Md5Email;
  sha256: Sha256Email;
};

type CleanLeadArgs = {
  emailHashes: EmailHashes;
  consentStatus?: ConsentStatus;
  userId: string;
};

export type CleanPurchaseArgs = {
  emailHashes: EmailHashes;
  plan: RecommendedPlanState;
  consentStatus: ConsentStatus;
  order: CleanOrderDetails;
  userId: string;
  promoCode: PromoCodeState;
};

export type CleanFastForwardArgs = {
  discountOffered: boolean;
  emailHashes: EmailHashes;
  userId: string;
};

export type CleanNoomPremiumArgs = {
  signupEltv13: number;
  products: string[];
  userId: string;
  emailHashes: EmailHashes;
};

export type CleanNoomSummitArgs = {
  signupEltv13: number;
  products: string[];
};

export type HandlerCallback<T> = (args: T) => void;

export interface HandlerOptions {
  retry?: boolean;
}

type incomingEvents = {
  init: unknown;
  startMainSurvey: unknown;
  lead: LeadArgs;
  purchase: PurchaseArgs;
  trialFastForward: FastForwardTrialArgs;
  viewContent: JsonObject;
  answerWhereDidYouHear: string[];
  noomPremium: NoomPremiumArgs;
  noomSummit: CleanNoomSummitArgs;
  purchaseIntent: JsonObject;
};

const cleanEvents = {
  init: [] as HandlerCallback<unknown>[],
  startMainSurvey: [] as HandlerCallback<unknown>[],
  lead: [] as HandlerCallback<CleanLeadArgs>[],
  purchase: [] as HandlerCallback<CleanPurchaseArgs>[],
  trialFastForward: [] as HandlerCallback<CleanFastForwardArgs>[],
  viewContent: [] as HandlerCallback<JsonObject>[],
  answerWhereDidYouHear: [] as HandlerCallback<string[]>[],
  noomPremium: [] as HandlerCallback<CleanNoomPremiumArgs>[],
  noomSummit: [] as HandlerCallback<CleanNoomSummitArgs>[],
  purchaseIntent: [] as HandlerCallback<JsonObject>[],
};

export type EVENTS = keyof typeof cleanEvents;

export async function cleanProps<T extends EVENTS>(
  props: incomingEvents[T]
): Promise<Parameters<typeof cleanEvents[T][0]>[0]> {
  const result = { ...props };
  if (props.surveyAnswers) {
    result.consentStatus = pick(
      props.surveyAnswers,
      surveyAnswersAllowlist
    ) as ConsentStatus;
    delete result.surveyAnswers;
  }
  if (props.userData) {
    // The only key allowed from userData is the id, so just pass that directly.
    result.userId = props.userData.user_id;
    delete result.userData;
  }
  if (props.email) {
    result.emailHashes = {
      sha1: await toSha1(props.email),
      md5: md5(props.email),
      sha256: await toSha256(props.email),
    } as EmailHashes;
    delete result.email;
  }
  if (props.order) {
    result.order = pick(
      props.order,
      orderDetailsAllowlist
    ) as CleanOrderDetails;
  }
  return result;
}

let initGate: Promise<any>;

/**
 * Fire a conversion pixel event and pass in necessary props.
 * */
export async function firePixelEvent<T extends EVENTS>(
  event: T,
  props: incomingEvents[T]
): Promise<void> {
  const cleanedProps = await cleanProps<T>(props);
  await initGate;

  const handlers = cleanEvents[event];
  const promises = handlers.map((handler) => handler(cleanedProps));

  await Promise.all(promises);
}

/**
 * Register a conversion pixel event handler.
 * */
export function registerHandler<T extends EVENTS>(
  event: T,
  callback: typeof cleanEvents[T][0],
  options: HandlerOptions = {}
) {
  // Retry is on by default. Need to explicitly pass in false to turn off.
  const maxAttempts = options?.retry === false ? 1 : PIXEL_RETRY_MAX_ATTEMPTS;

  function wrappedCallback(
    args: Parameters<typeof callback>[0]
  ): Promise<void> {
    return fireCallbackWithRetry(
      callback,
      args,
      PIXEL_RETRY_DELAY_MS,
      maxAttempts
    );
  }

  cleanEvents[event].push(wrappedCallback);
}

/**
 * Util for unit tests -- unlikely you would ever want to call this in production code
 */
export function clearAllHandlers() {
  typedKeys(cleanEvents).forEach((key) => {
    cleanEvents[key] = [];
  });
}

export async function initializeHandlers() {
  if (initGate) {
    return initGate;
  }

  // Break into async loaded task. Doing this both to prioritize our own
  // code loading and to avoid circular dependencies within the pixels.
  initGate = bootstrapConsent()
    .then(() => import(/* webpackChunkName: "publishers" */ "./publishers"))
    .then((publishers) => {
      publishers.initializePublishers();
    });

  return initGate;
}

import { captureException } from "src/utils/error";
import { loadBing } from "./bing";
import { loadTaboola } from "./taboola";
import { loadFacebookPixel } from "./facebook";
import { loadGA } from "./ga";
import { loadImpact } from "./impact";
import { loadPinterest } from "./pinterest";
import { loadTikTok } from "./tiktok";
import { loadReddit } from "./reddit";
import { loadRoktPixel } from "src/vendor/rokt";
import { perfTrack } from "src/utils/timing";
import { loadSafeOptPixel } from "src/vendor/safeopt";
import { loadHorizon } from "./horizon";
import { isPrerender } from "src/utils/userSegment/features";
import { loadYelp } from "./yelp";
import { loadTwitter } from "./twitter";
import { loadGoogleTagManager } from "./gtm";
import { loadSingular } from "./singular";

/**
 * - bootstrap: Occurs as early as possible, immediately after our application code has parsed and loaded.
 * - after-context-init: Occurs after we have performed general data setup (server context, user data, etc)
 * - rendered: Occurs after the user sees visible content on the page
 * - idle: Occurs with slight delay after rendered. On supporting browsers this is tied to actual idle, for others a timeout.
 */
type Phase = "bootstrap" | "after-context-init" | "rendered" | "idle";

const phaseMap: Record<Phase, (() => Promise<any>)[]> = isPrerender()
  ? { bootstrap: [], "after-context-init": [], rendered: [], idle: [] }
  : {
      bootstrap: [],
      "after-context-init": [loadFacebookPixel],
      rendered: [loadBing, loadGA, queueIdlePhase],
      idle: [
        loadImpact,
        loadPinterest,
        loadTikTok,
        loadReddit,
        loadRoktPixel,
        loadSafeOptPixel,
        loadHorizon,
        loadTaboola,
        loadYelp,
        loadTwitter,
        loadGoogleTagManager,
        loadSingular,
        perfTrack,
      ],
    };

async function queueIdlePhase() {
  // Defer idle libs until common FCP timing
  setTimeout(() => {
    // And then wait for actual idle
    if (window.requestIdleCallback) {
      requestIdleCallback(
        () => {
          triggerLoadPhase("idle");
        },
        { timeout: 10_000 }
      );
    } else {
      // Unless we can't
      triggerLoadPhase("idle");
    }
  }, 3000);
}

export function onLoadPhase(phase: Phase, fn: () => Promise<any>) {
  phaseMap[phase].push(fn);
}

/**
 * Used to denote key events in the application init lifecycle and to kick off appropriate
 * library loading after that point.
 *
 * Generally this should not be called directly outside of init code.
 */
export function triggerLoadPhase(phase: Phase) {
  const ret = Promise.all(
    phaseMap[phase].map((fn) =>
      fn().catch((err) => {
        // These should not be fatal anywhere.
        captureException(err);
      })
    )
  );

  phaseMap[phase] = [];
  return ret;
}

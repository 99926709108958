import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
export const FadeIn = styled.div<{ duration?: number; delay?: number }>`
  opacity: 0;
  animation-name: ${fadeIn};
  animation-duration: ${(props) => props.duration || 2}s;
  animation-delay: ${(props) => (props.delay !== undefined ? props.delay : 1)}s;
  animation-fill-mode: forwards;
`;

export const delayedFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeInAndOut = keyframes`
  from {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const FadeInAndOut = styled.div<{ duration?: number; delay?: number }>`
  opacity: 0;
  animation-name: ${fadeInAndOut};
  animation-duration: ${(props) => props.duration || 3}s;
  animation-delay: ${(props) => props.delay || 0}s;
  animation-fill-mode: forwards;
`;

const fadeOut = keyframes`
from {
  opacity: 1;
}
to {
  opacity: 0;
}`;

export const fadeOutAnimation = css`
  opacity: 1;
  animation: ${fadeOut} 3s ease-in;
`;

export const FadeOut = styled.div<{ duration?: number; delay?: number }>`
  animation-name: ${fadeOut};
  animation-duration: ${(props) => props.duration || 2}s;
  animation-delay: ${(props) => (props.delay !== undefined ? props.delay : 1)}s;
  animation-fill-mode: forwards;
`;

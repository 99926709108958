import { routeConstants } from "../constants";
import { checkSegments } from "./util";
import { isUS } from "./locale";

export const isAppEmail = checkSegments({
  oneOf: [routeConstants.appEmail],
});

export const isAppIosBase = checkSegments({
  oneOf: [routeConstants.app14DayMain],
});

export const isAppAndroidBase = checkSegments({
  oneOf: [routeConstants.app14DayWebMain],
});

export const isAppIosWinback = checkSegments({
  oneOf: [routeConstants.app14DayReturning],
});

export const isAppAndroidWinback = checkSegments({
  oneOf: [routeConstants.app14DayFtWeb],
});

export const isAppIos = checkSegments({
  oneOf: [isAppIosBase, isAppIosWinback],
});

export const isAppAndroid = checkSegments({
  oneOf: [isAppAndroidBase, isAppAndroidWinback],
});

export const isAppBaseRoute = checkSegments({
  oneOf: [isAppIosBase, isAppAndroidBase],
});

export const isInAppWinback = checkSegments({
  oneOf: [isAppAndroidWinback, isAppIosWinback],
});

export const isInApp = checkSegments({
  oneOf: [isInAppWinback, isAppBaseRoute, routeConstants.appUpsell],
});

export const isUSInAppWinback = checkSegments({
  allOf: [isUS],
  oneOf: [isInAppWinback],
});

import {
  NoomBuyflowEventProto,
  NoomBuyflowEventProto_SharedPropertiesProto,
} from "@noom/noom-contracts/noom_contracts/events/buyflow/buyflow";

import { MMPEventProto } from "@noom/noom-contracts/noom_contracts/events/mobile_measurement_partner/mmp_event";

export interface WellknownMixpanelEvents {
  $experiment_started: {
    "Experiment name": string;
    "Variant name": string;
  };
  OnMeristemExperimentDecided: {
    experimentName: string;
    /** @deprecated Use meristemSha instead. */
    sha: string;
    variationName: string;
  };
}

// Const array so we can use this in runtime
export const referralMethods = [
  "sms",
  "email",
  "link",
  "shareApi",
  "buyflow",
  "familyPlan",
] as const;

// Use array above to create a type which we can use to generate proto files
// in noom-contract repo
export type ReferralMethod = Uppercase<typeof referralMethods[number]>;

/**
 * Events that does not conform to a proto schema (created from
 * NoomBuyflowEventSharedProperties interface) will not pass the validation
 * in event ingestion lambda.
 * NOOM_BUYFLOW_EVENT_SHARED_PROPERTIES is a duplicated list of the properties from
 * the interface above used to filter the properties sent to data lake. We need
 * this duplication so that we can filter out the invalid properties in runtime
 * and typings ensure that this list will always match the interface above.
 */
export const NOOM_BUYFLOW_EVENT_SHARED_PROPERTIES: Record<
  keyof NoomBuyflowEventProto_SharedPropertiesProto,
  true
> = {
  browserFamily: true,
  browserMajorVersion: true,
  buyflowGitSha: true,
  condensedContextType: true,
  consentFramework: true,
  currentUrl: true,
  deviceBrand: true,
  deviceFamily: true,
  deviceIsMobile: true,
  fullStorySessionUrl: true,
  geoIpCity: true,
  geoIpContinent: true,
  geoIpCountry: true,
  geoIpSubdivision: true,
  growthUserId: true,
  hasBeenVisible: true,
  httpUserAgent: true,
  initialUrl: true,
  isUnloading: true,
  isVisible: true,
  language: true,
  meristemContextType: true,
  meristemExperiments: true,
  meristemJourney: true,
  meristemSha: true,
  meristemTreatment: true,
  meristemVariations: true,
  noomPlanId: true,
  osFamily: true,
  osMajor: true,
  pageSet: true,
  referrerUrl: true,
  routeId: true,
  trafficType: true,
  utmCampaign: true,
  utmContent: true,
  utmMedium: true,
  utmSource: true,
  utmTerm: true,
};

export interface NoomBanditEvents {
  BanditFeedbackProvided: {
    banditFeedbackValue: number;
    banditKey: string;
    banditRecommendationUniqueId: string;
  };
  BanditRecommendationProvided: {
    banditKey: string;
    banditRecommendationUniqueId: string;
    recommendedBanditArm: string;
  };
}

export type NoomBuyflowEvents = NoomBuyflowEventProto;

export type NoomMmpEvents = MMPEventProto;

import React, { ReactNode } from "react";
import styled from "@emotion/styled";

import { HeaderToolbar } from "../core/HeaderToolbar";
import { MainColumn } from "../core/BodyLayout";
import { useQuickSilverClickLoader } from "src/test/quickSilver/helpers/useQuickSilverClickLoader";
import { NoomLogo } from "../Logo";
import NoomForWorkLogo from "src/static/noom_for_work_logo.png";
import SurveySkipButton from "src/components/linear-buyflow/SurveySkipButton";

const Content = styled(MainColumn)`
  display: grid;
  // Paired 1fr ensures that the logo is centered
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  height: 48px;
`;

const ForWorkLogo = styled.img`
  height: 25px;
  margin: 14px;
  grid-column: 2;
  grid-row: 1;
`;

export function Header({
  pill = true,
  logo = true,
  pillElement,
  children,
  className,
}: {
  pill?: boolean;
  logo?: boolean;
  pillElement?: JSX.Element;
  children?: ReactNode;
  className?: string;
}) {
  const onClickQuickSilverLoader = useQuickSilverClickLoader();

  return (
    <HeaderToolbar className={className}>
      <Content>
        {children}
        {logo && (
          <NoomLogo
            showPill={pill}
            pillElement={pillElement}
            css={{ height: 19, margin: 14, gridColumn: 2, gridRow: 1 }}
            onSecretClick={onClickQuickSilverLoader} // onClick used for debug purposes to load quicksilver
          />
        )}
      </Content>
    </HeaderToolbar>
  );
}

export function ForWorkHeader({
  handleSkip,
  children,
}: {
  handleSkip?: () => void;
  children?: ReactNode;
}) {
  return (
    <HeaderToolbar>
      <Content>
        {children}
        <ForWorkLogo src={NoomForWorkLogo} alt="" />
        {handleSkip && <SurveySkipButton onClickSkip={handleSkip} />}
      </Content>
    </HeaderToolbar>
  );
}
